@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import '~bootstrap/scss/bootstrap.scss';
.hover-zoom{
    transition: all 0.5s linear;
}
.hover-zoom:hover{
    transform: scale(1.05);
}
.pre-line{
    white-space: pre-line !important;
}
